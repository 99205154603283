body.dark {
    background-color: #333;
    color: #fff;
}

body.dark .w3-blue {
    background-color: #494949 !important;
    color: #fff !important;
}

body.dark .w3-card, body.dark .w3-card-4 {
    background-color: #6d6d6d !important;
    color: #fff !important;
}

body.dark .w3-light-gray {
    background-color: #102027 !important;
    color: #fff !important;
}

body.dark button.w3-pink {
    background-color: #512da8 !important;
    color: #fff !important;
}

.impressum-footer {
    position: fixed;
    bottom: 5px;
    width: 100%;
    text-align: center;
    font-size: 12px;
    opacity: 0.3;
    transition: opacity 0.3s;
}

.impressum-footer:hover {
    opacity: 1;
}


.yellow {
    background-color: #fbbd08 !important;
  }
  
