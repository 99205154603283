/* src/components/NavbarCritical.css */
.navbar {
    background-color: #fff;        
    border-bottom: 1px solid #eee;
    font-family: Helvetica, Arial, sans-serif;
    padding: 0.75rem 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between; /* brand on the left, menu on the right */
}

.brand {
    font-size: 1.25rem;
    font-weight: bold;
    cursor: pointer;
}

.menu-items {
    display: flex;
    gap: 0.5rem;
}

.nav-button {
    background: none !important;
    color: #333 !important;
    border: 1px solid #ddd !important;
    margin: 0 !important;
    padding: 0.5rem 0.75rem !important;
    font-weight: 600 !important;
    border-radius: 4px !important;
}

.nav-button:hover {
    background-color: #f2f2f2 !important;
}

/* Additional critical styles */
.no-border {
    border: none !important;
    box-shadow: none !important;
}

.modal-btn i {
    margin-right: 0 !important;
}
